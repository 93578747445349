
import { ProduceTypes } from "@/store/modules/produce/produce.types";
import { Component, Ref, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const produce = namespace("Produce");

@Component({
  components: {
    ProduceList: () => import("@/components/produce/ProduceList.vue"),
  },
})
export default class ProducesView extends Vue {
  @produce.Mutation(ProduceTypes.SET_ADD_PRODUCE_DIALOG)
  public setAddProduce!: (addProduce: boolean) => void;

  @produce.Action(ProduceTypes.LOAD_PRODUCES)
  public loadProduces!: () => void;

  @produce.State("loadingProduce")
  public loadingProduces!: boolean;

  public pageSizes = [10, 15, 20];

  public pageSize = 15;

  public query = "";

  @Ref("produceList") public produceListRef!: { queryProduces(): void };

  refresh(): void {
    this.produceListRef.queryProduces();
  }

  openAddProduceDialog(): void {
    this.setAddProduce(true);
  }
}
